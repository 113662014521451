import { Injectable } from '@angular/core';
import { Transaction } from '@app/transactions/transaction';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class FeeService {
    public transaction$: BehaviorSubject<Transaction | null> = new BehaviorSubject<Transaction | null>(null);
    private currentTransaction: Transaction | null = null;
    public touchInput$: Subject<any> = new Subject();

    public getTransaction(): Observable<Transaction> {
        return this.transaction$.asObservable() as Observable<Transaction>;
    }

    public setTransaction(transaction: Transaction): void {
        this.currentTransaction = transaction;
        this.transaction$.next(transaction);
    }

    public getCurrentTransaction(): Transaction | null {
        return this.currentTransaction;
    }
}
