import {
    Component,
    Input,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    HostListener,
    ViewChild,
    ElementRef,
    ChangeDetectorRef,
    AfterViewInit,
    OnChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { CountryProvider } from '@app/core/country-provider/country-provider';
import { IBankRepository } from '@app/core/country-provider/repositories/bank-repository-interface';
import { AnimationHeaderMovilHelper } from '@app/core/helpers/animation-header-movil.helper';
import { ICountryData, UserCountryService } from '@app/core/services/user-country.service';
import { HeaderCountryService } from './header-country.service';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';

export const userLinks: Array<{ [key: string]: any }> = [
    {
        title: 'bottom_page_account_log_in_button',
        link: '/my/login'
    },
    {
        title: 'bottom_page_account_sign_in_button',
        link: '/my/signup'
    }
];

@Component({
    selector: 'app-header',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit, OnChanges {
    @ViewChild('header') public header!: ElementRef;
    @Input() public link: string = '/';
    @Input() public type: string = 'panel';
    @Input() public showItemHeader: boolean = true;
    @Input() public showLoginAndSignUp: boolean = true;
    @Input() public withBanner: boolean = false;
    @Output() public buttonAction: EventEmitter<boolean> = new EventEmitter<boolean>();
    public showNewContent: boolean = false;
    public defaultRouterLink: string = '';
    public defaultImgUrl: string = '';
    public defaultImgAltText: string = '';
    private countryProvider: CountryProvider = new CountryProvider();
    private animationHeaderMovil!: AnimationHeaderMovilHelper;
    public countries: Array<any> = [];
    public selectedCountry: { [key: string]: string } = {
        group_id: 'ars_bank',
        alt: 'Argentina'
    };

    public wikiLinks: Array<{ [key: string]: string }> = [
        {
            title: 'header_associates_program_button',
            link: '/wiki/referral-program'
        },
        {
            title: 'bottom_page_legal_warranty_button',
            link: '/wiki/warranty'
        },
        {
            title: 'header_my_order_button',
            link: '/transaction-states'
        }
    ];
    public userLinks: Array<{ [key: string]: any }> = userLinks;

    public constructor(
        private userCountryService: UserCountryService,
        private headerService: HeaderCountryService,
        private browserService: PlatformBrowserService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        this.selectDefaultCountry();
        this.fillCountries();
    }

    public ngOnChanges(): void {
        if (!this.browserService.isBrowser) {
            this.withBanner = false;

            return;
        }

        if (this.withBanner) {
            this.withBanner = this.showBanner();
        }
    }

    public ngAfterViewInit(): void {
        this.animationHeaderMovil = new AnimationHeaderMovilHelper(this.header);
    }

    public showBanner(): boolean {
        const dateCloseBanner: Date = new Date(2024, 8, 19);
        const currentDate: Date = new Date();
        const bannerLocalStorage: boolean = localStorage.getItem('showBanner') ? true : false;
        if (currentDate < dateCloseBanner && !bannerLocalStorage) {
            this.defaultRouterLink = '/a/banco_bol/wise_usd/0/100';
            this.defaultImgUrl = 'banco_bol';
            this.defaultImgAltText = 'Transferencia en pesos bolivianos';

            return true;
        }

        return false;
    }

    public closeBanner(): void {
        this.withBanner = false;
        localStorage.setItem('showBanner', 'false');
    }

    private setCountry(country: IBankRepository): { [key: string]: string } {
        let selectedCountry: { [key: string]: string } = {
            group_id: country.groupId,
            alt: country.countryName,
            positionSprite: country.widthPositionSprite + ' ' + country.heightPositionSprite,
            title: country.title,
            langCode: country.langCode,
            urlHref: country.urlHref
        };

        return selectedCountry;
    }

    private selectDefaultCountry(): void {
        this.userCountryService.getCountry().subscribe((country): void => {
            this.selectedCountry = this.setCountry(this.countryProvider.getCountry(country.code));
            this.changeDetectorRef.markForCheck();
        });
    }

    private fillCountries(): void {
        Object.keys(this.countryProvider.data).forEach((key): void => {
            let country: { [key: string]: string } = {
                code: key,
                group_id: this.countryProvider.data[key].groupId,
                name: this.countryProvider.data[key].countryName,
                title: this.countryProvider.data[key].title,
                urlHref: this.countryProvider.data[key].urlHref,
                positionSprite:
                    this.countryProvider.data[key].widthPositionSprite + ' ' + this.countryProvider.data[key].heightPositionSprite
            };
            this.countries.push(country);
        });
        this.changeDetectorRef.markForCheck();
    }

    public trackByCountry(_index: number, country: any): any {
        return country.key;
    }

    public selectCountry(event: Event, country: ICountryData): void {
        event.preventDefault();
        this.userCountryService.selectCustomCountry({ code: country.code, ip: '' });
        let currentUrl: string =
            this.selectedCountry.langCode === '' ? this.router.url : this.router.url.replace('/' + this.selectedCountry.langCode, '');
        const prefixUrl: string =
            this.countryProvider.getCountry(country.code).langCode === ''
                ? ''
                : '/' + this.countryProvider.getCountry(country.code).langCode;

        const newUrl: string = prefixUrl + currentUrl;
        this.router.navigateByUrl(newUrl);
        this.selectedCountry = this.setCountry(this.countryProvider.getCountry(country.code));
        this.headerService.selectedCountry$.next(country);
        this.changeDetectorRef.markForCheck();
    }

    public linkWithLang(link: string): string {
        let redirectToDashboard: boolean = link.includes('/my/');
        let langCode: string = this.selectedCountry.langCode && !redirectToDashboard ? '/' + this.selectedCountry.langCode : '';

        return langCode + link;
    }

    public openStateViewer(): void {
        this.router.navigateByUrl('transaction-states');
    }

    @HostListener('window:scroll', ['$event'])
    public headerScroll(_event: Event): void {
        if (this.withBanner) {
            this.animationHeaderMovil.HEADER_HEIGHT = 170;
        }
        this.animationHeaderMovil.headerScrollMobile();
        this.animationHeaderMovil.headerScrollDesktop();
        this.changeDetectorRef.markForCheck();
    }

    public changeBannerWithDate(): void {
        if (!this.browserService.isBrowser) {
            return;
        }
        this.defaultRouterLink = '/a/advcash/banco_ves/100';
        this.defaultImgUrl = 'volet';
        this.defaultImgAltText = 'Saldo Volet';

        let newDate: any = new Date('2023-08-22T10:00:00');
        let currentDate: any = new Date();

        if (currentDate > newDate) {
            this.showNewContent = true;
            this.defaultRouterLink = '/a/pix/banco/100';
            this.defaultImgUrl = 'pix';
            this.defaultImgAltText = 'Saldo Pix';
        }
    }
}
