import { TranslateService } from '@ngx-translate/core';
import { SeoService } from './seo.service';
import { Injectable, inject } from '@angular/core';
import { combineLatest } from 'rxjs';
import { environment } from 'environments/environment.base';

interface IMetadata {
    title?: string;
    description?: string;
}

interface IReceivedOpenGraphData {
    url?: string;
    urlOgImage?: string;
    meta?: IMetadata;
}

interface IOpenGraphData {
    title: string;
    description: string;
    siteName: string;
    url: string;
    twitterImage: string;
    twitterCard: string;
    twitterSite: string;
    twitterTitle: string;
    twitterDescription: string;
}

@Injectable({
    providedIn: 'root'
})
export class OgService {
    private translateService: TranslateService;
    private seoService: SeoService;

    public constructor() {
        this.translateService = inject(TranslateService);
        this.seoService = inject(SeoService);
    }

    public addOpenGraphMetadata({ url = '', urlOgImage = '', meta }: IReceivedOpenGraphData): void {
        combineLatest([
            this.translateService.get('meta_set_og_default_title'),
            this.translateService.get('meta_set_og_default_description')
        ]).subscribe(([ogTitle, ogDescription]: Array<string>) => {
            const ogData: IOpenGraphData = {
                title: meta?.title ?? ogTitle,
                description: meta?.description ?? ogDescription,
                siteName: 'Saldoar',
                url: environment.CANONICAL_ORIGIN_URL + url,
                twitterImage:
                    environment.CANONICAL_ORIGIN_URL + (urlOgImage === '' ? 'assets/images/og/og-cambia-saldo-v2.png' : urlOgImage),
                twitterCard: 'summary',
                twitterSite: '@SaldoComAr',
                twitterTitle: ogTitle,
                twitterDescription: ogDescription
            };

            Object.entries(ogData).forEach(([key, value]) => {
                if (key.includes('twitter')) {
                    const cleanKey: string = key.replace('twitter', '').toLowerCase();
                    this.seoService.addOrUpdateMetaProperty(`twitter:${cleanKey}`, value);

                    return;
                }
                this.seoService.addOrUpdateMetaProperty(`og:${key}`, value);
            });
        });
    }
}
