import { Injectable, Inject } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { pathsSaldo } from 'environments/paths-saldo';
import { TransactionManagerService } from '../../../../ui/shared/services/transaction-key-manager.service';

@Injectable({
    providedIn: 'root'
})
export class TransactionInterceptor implements HttpInterceptor {
    private headers!: HttpRequest<any>;
    public constructor(
        @Inject(DOCUMENT) private document: Document,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        private transactionManagerService: TransactionManagerService
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let urlNotFramework: string = this.document.location.href;
        if (urlNotFramework.includes('/t/') && request.url.includes(environment.API_URL + pathsSaldo.API_PATH)) {
            let url: Array<string> = urlNotFramework.split('/');
            let transactionKey: string = url[6];
            let transactionMid: string = url[7];

            if (urlNotFramework.includes('/v3/')) {
                transactionKey = url[7];
                transactionMid = url[8];
                this.transactionManagerService.setTransactionMid(transactionMid);
                this.transactionManagerService.setTransactionKey(transactionKey);
            }

            this.headers = request.clone({
                setHeaders: {
                    'Transaction-Key': transactionKey,
                    'Transaction-Mid': transactionMid
                }
            });

            return next.handle(this.headers);
        }

        return next.handle(request);
    }
}
