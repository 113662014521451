import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, OnDestroy } from '@angular/core';
import { System } from '@app/core/resources/system';
import { Transaction } from '@app/transactions/transaction';
import { Subscription } from 'rxjs';
import { FeeProvider } from './fee-provider';
import { FeeService } from './fee.service';
import { InformationRepository } from './information-repository/information-repository';

@Component({
    selector: 'app-fee-message',
    templateUrl: './fee-message.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./fee-message.component.scss']
})
export class FeeMessageComponent implements OnInit, OnDestroy {
    @Input() public way: 'send' | 'receive' | 'not_way' = 'not_way';
    @Input() public isTransactionData: boolean = false;
    public transaction: Transaction = new Transaction();
    public feeStandard: number = 0;
    public feeTrueSkriller: number = 0;
    public feeSend: number = 0;
    public feeReceive: number = 0;
    public sendSystem: System = new System();
    public receiveSystem: System = new System();
    public toggleChecked: boolean = false;
    public amountNet: number = 0;
    public informationRepository: InformationRepository = new InformationRepository();
    private feeProvider: FeeProvider;
    public finalFee: number = 0;
    private subscriptions: Array<Subscription> = [];

    public constructor(private feeService: FeeService, private changeDetectorRef: ChangeDetectorRef) {
        this.feeProvider = new FeeProvider();
    }

    public ngOnInit(): void {
        this.setupSubscriptions();

        const currentTransaction: Transaction | null = this.feeService.getCurrentTransaction();
        if (currentTransaction) {
            this.processTransaction(currentTransaction);
        }
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    private setupSubscriptions(): void {
        const transactionSub: Subscription = this.feeService.getTransaction().subscribe((transaction) => {
            if (transaction) {
                this.processTransaction(transaction);
            }
        });

        const inputSub: Subscription = this.feeService.touchInput$.subscribe(() => {
            this.toggleChecked = false;
            this.changeDetectorRef.detectChanges();
        });

        this.subscriptions.push(transactionSub, inputSub);
    }

    private processTransaction(transaction: Transaction): void {
        this.transaction = transaction;
        this.feeProvider.setTransaction(this.transaction);
        this.changeAmountNetIfToggleChecked();
        this.setSystems();
        this.calculateFee();
        this.changeDetectorRef.detectChanges();
    }

    private calculateFee(): void {
        this.feeSend = this.feeProvider.getBySystem('receive', 1).getFee();
        this.feeReceive = this.feeProvider.getBySystem('send', 2).getFee();
        this.finalFee = this.feeProvider.getBySystem('send', 2).getFinalFee(this.transaction.attributes.amount2);
        this.changeDetectorRef.detectChanges();
    }

    private setSystems(): void {
        this.sendSystem = this.transaction.relationships.system1.data ?? new System();
        this.receiveSystem = this.transaction.relationships.system2.data ?? new System();
    }

    private changeAmountNetIfToggleChecked(): void {
        if (!this.toggleChecked) {
            return;
        }

        this.amountNet = this.feeProvider.getBySystem('send', 2).getAmountWithoutFee();
    }

    private updateAmountToggle(): void {
        if (!this.sendSystem || !this.receiveSystem) {
            return;
        }

        this.amountNet = this.transaction.attributes.amount2;
        this.transaction.attributes.__operation = 'send';
        this.transaction.attributes.amount2 = this.feeProvider.getBySystem('send', 2).getAmountWithFee();
        this.calculateFee();
    }

    public onChangeSlideToggle(): void {
        this.toggleChecked = !this.toggleChecked;

        if (this.toggleChecked) {
            this.updateAmountToggle();
        } else {
            this.transaction.attributes.amount2 = this.feeProvider.getBySystem('send', 2).getAmountWithoutFee();
            this.calculateFee();
        }
    }
}
