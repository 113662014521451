import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
    ElementRef,
    Renderer2,
    HostListener,
    SimpleChanges,
    OnDestroy
} from '@angular/core';
import { System } from '@app/core/resources/system';
import { environment } from '../../../../../environments/environment';
import { map, startWith, debounceTime, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Transaction } from '@app/transactions/transaction';
import { FormControl } from '@angular/forms';
import { Destroyer } from '@app/core/services/destroyer';
import { animate, style, transition, state, trigger } from '@angular/animations';
import { PlatformBrowserService } from '@app/core/services/platform-browser.service';
import { tuiPure } from '@taiga-ui/cdk';
import TransactionAmountCalculator from '@app/transactions/transaction-amount-calculator';
import { FormatDecimalsHelper } from '@app/core/helpers/format-decimals-helper';

export interface ICurrencyMappedSystems {
    [currency: string]: Array<System>;
}

export const mapSystemsByCurrency: (systems: Array<System>) => ICurrencyMappedSystems = (
    systems: Array<System>
): ICurrencyMappedSystems => {
    let currencyMappedSystems: ICurrencyMappedSystems = {};
    systems.forEach((system): void => {
        let key: string = system.attributes.market === 'crypto' ? 'CRYPTO' : system.attributes.currency;
        let currency_systems: Array<System> = currencyMappedSystems[key];
        if (Array.isArray(currency_systems)) {
            currency_systems.push(system);
        } else {
            currencyMappedSystems[key] = [system];
        }
    });

    return currencyMappedSystems;
};

@Component({
    selector: 'app-system-select',
    templateUrl: './system-select.component.html',
    styleUrls: ['./system-select.component.scss'],
    animations: [
        trigger('inputNumberAnimation', [
            state('open', style({})),
            state('closed', style({})),
            transition('open <=> closed', [
                animate('0.000000001s ease-in', style({ opacity: 0 })),
                animate('0.6s ease-in', style({ opacity: 1 }))
            ])
        ])
    ]
})
export class SystemSelectComponent implements OnInit, OnChanges, OnDestroy {
    @Input() public systems: Array<System> = [];
    @Input() public selectedSystem: System | null | undefined = new System();
    @Input() public counterSystem: System | null | undefined = new System();
    @Input() public mode: 'send' | 'receive' | 'all' = 'all';
    @Input() public value: number = 0;
    @Input() public valueCounter: number = 0;
    @Input() public isOnlyTwoSystems: boolean = false;

    @Output() public inFocus: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() public valueChange: EventEmitter<number> = new EventEmitter<number>();
    @Output() public selectedSystemChange: EventEmitter<System> = new EventEmitter<System>();
    @Output() public transactionUpdate: EventEmitter<Transaction> = new EventEmitter<Transaction>();

    @ViewChild('valueChangeInput', { static: true }) public valueChangeInput: undefined | ElementRef;
    @ViewChild('listSystem') public listSystem!: ElementRef;
    @ViewChild('containerListAndSearchSystem') public containerListAndSearchSystem!: ElementRef;
    @ViewChild('system') public system!: ElementRef;
    @ViewChild('closeSystem') public closeSystem!: ElementRef;
    @ViewChild('selectedSystemTab') public selectedSystemTab!: ElementRef;
    @ViewChild('systemSearchInput', { static: true }) public systemSearchInput: undefined | ElementRef;

    public url: string = environment.API_URL;
    public systemsByCurrency: Observable<ICurrencyMappedSystems> = new Observable();
    public searchCtrl: FormControl = new FormControl('');
    public systemsQuotations: { [system: string]: string } = {};
    public checkViewPort: boolean = false;
    public systemsDictionary: Array<System | undefined> = [];
    public preselectedSystemId: string = '';
    public systems_list_was_open: boolean = false;
    public is_systems_list_open: boolean = false;
    public is_focused: boolean = false;
    public run_animation_input: boolean = false;

    private readonly SYSTEM_IMG_HEIGHT: number = 100;
    private preselectedIndex: number = 0;
    private destroyer: Destroyer = new Destroyer();

    public constructor(private renderer: Renderer2, private browserService: PlatformBrowserService) {}

    public ngOnInit(): void {
        if (this.browserService.isBrowser) {
            this.onCheckViewPort();
        }
        this.fillSystemListByCurrency();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        // Utilizo esta funcion en el onChanges para que tome los cambios sin importar el origen
        this.resizeInput();
        if (changes.value && !this.is_focused) {
            this.run_animation_input = !this.run_animation_input;
        }
    }

    public ngOnDestroy(): void {
        this.destroyer.destroy();
    }

    public trackBySystem(_index: number, system: System): string {
        return system.id;
    }

    public showListSystem(): void {
        if (this.isOnlyTwoSystems) {
            return;
        }
        this.systems_list_was_open = true;
        this.displaySystem(true);
        this.listSystem.nativeElement.focus({ preventScroll: true });
        this.makeScrollSelectedSystem();
        this.calculateQuotationOfSystemsList();
    }

    private makeScrollSelectedSystem(): void {
        this.updatePreselectedSystemId();
        if (this.containerListAndSearchSystem.nativeElement.scrollTo) {
            this.containerListAndSearchSystem.nativeElement.scrollTo(0, this.SYSTEM_IMG_HEIGHT * this.preselectedIndex);
        }
    }

    public closeListSystem(): void {
        this.displaySystem(false);
        this.searchCtrl.setValue('');
    }

    public selectSystemList(system: System): void {
        this.selectedSystem = system;
        this.closeListSystem();
    }

    public restartSystemsSearch(): void {
        this.searchCtrl.setValue('');
    }

    public focusInputSearch(): void {
        if (this.systemSearchInput === undefined) {
            return;
        }
        this.systemSearchInput.nativeElement.focus();
    }

    public onCheckViewPort(): void {
        if (window.screen.width < 420) {
            this.checkViewPort = true;
        }
    }

    public onStopPropagation(event: Event): void {
        event.stopPropagation();
    }

    @HostListener('keydown', ['$event'])
    public handleKeyDown(event: KeyboardEvent): void {
        switch (true) {
            case event.key === 'Escape':
            case event.key === 'Enter':
                break;
            case event.key === 'ArrowDown':
            case event.key === 'ArrowUp':
                event.preventDefault();
                break;
            default:
                this.systemSearchInput?.nativeElement.focus({ preventScroll: true });
                break;
        }
    }

    @HostListener('keyup', ['$event'])
    public handleKeyUp(event: KeyboardEvent): void {
        switch (true) {
            case event.key === 'Escape':
                this.closeListSystem();
                event.stopPropagation();
                this.selectedSystemTab?.nativeElement.focus();
                break;
            case event.key === 'ArrowDown':
                event.stopPropagation();
                this.listMoveDown();
                break;
            case event.key === 'ArrowUp':
                event.stopPropagation();
                this.listMoveUp();
                break;
            case event.key === 'Enter':
                if (Object.is(this.selectedSystemTab.nativeElement, document.activeElement)) {
                    this.showListSystem();

                    return;
                }
                this.selectSystemListWithEnter();
                break;
        }
    }

    @tuiPure
    public resize(valueInputResize: string): void {
        this.renderer.setStyle(this.valueChangeInput?.nativeElement, 'font-size', valueInputResize);
    }

    public resizeInput(): void {
        let lengthInputValue: number = 0;
        // non strict comparison, null or undefined
        if (this.value) {
            lengthInputValue = this.value.toString().replace('.', '').length;
        }
        switch (true) {
            case lengthInputValue < 8:
                this.resize('250%');
                break;
            case lengthInputValue < 10:
                this.resize('210%');
                break;
            case lengthInputValue < 12:
                this.resize('170%');
                break;
            default:
                this.resize('130%');
                break;
        }
    }

    public focusAmountInput(): void {
        this.inFocus.emit(true);
    }

    public focus(): void {
        this.is_focused = true;
    }

    public blur(): void {
        this.is_focused = false;
    }

    private fillSystemListByCurrency(): void {
        this.systemsByCurrency = this.searchCtrl.valueChanges.pipe(
            debounceTime(200),
            startWith(''),
            map((text: string): Array<System> => {
                return this.filterSystemsByName(this.systems, text);
            }),
            map((systems: Array<System>): ICurrencyMappedSystems => {
                return mapSystemsByCurrency(systems);
            }),
            tap((currencyMappedSystems): void => {
                if (!this.selectedSystem) {
                    return;
                }
                this.systemsDictionary = [];
                let currencies: Array<string> = Object.keys(currencyMappedSystems).sort();
                for (let currencyKey of currencies) {
                    for (let system of currencyMappedSystems[currencyKey]) {
                        this.systemsDictionary.push(system);
                    }
                }
                this.preselectedIndex = this.systemsDictionary.indexOf(this.selectedSystem);
                this.updatePreselectedSystemId();
            }),
            this.destroyer.pipe()
        );
    }

    private calculateQuotationOfSystemsList(): void {
        let formatDecimal: FormatDecimalsHelper = new FormatDecimalsHelper();
        this.systems
            .filter((system): boolean => {
                if (this.selectedSystem) {
                    return system.id !== this.selectedSystem.id;
                }

                return false;
            })
            .forEach((system): void => {
                if (!this.counterSystem) {
                    return;
                }

                let transactionAmountCalculator: TransactionAmountCalculator = new TransactionAmountCalculator();

                if (this.mode === 'send') {
                    let amount1: number = transactionAmountCalculator.getSendAmount(system, this.counterSystem, this.valueCounter);
                    this.systemsQuotations[system.id] = formatDecimal.formatDecimal(amount1, system.attributes.decimal_places);
                } else if (this.mode === 'receive') {
                    let amount2: number = transactionAmountCalculator.getReceiveAmount(system, this.counterSystem, this.valueCounter);
                    this.systemsQuotations[system.id] = formatDecimal.formatDecimal(amount2, system.attributes.decimal_places);
                }
            });
    }

    private listMoveUp(): void {
        this.containerListAndSearchSystem.nativeElement.focus();
        if (this.preselectedIndex <= 0) {
            return;
        }
        this.preselectedIndex -= 1;
        this.makeScrollSelectedSystem();
    }

    private listMoveDown(): void {
        this.containerListAndSearchSystem.nativeElement.focus();
        if (this.preselectedIndex >= this.systemsDictionary.length - 1) {
            return;
        }
        this.preselectedIndex += 1;
        this.makeScrollSelectedSystem();
    }

    private selectSystemListWithEnter(): void {
        let system: System | undefined = this.systemsDictionary[this.preselectedIndex];
        if (system === undefined) {
            return;
        }
        if (this.is_systems_list_open) {
            this.selectedSystemChange.emit(system);
            this.selectSystemList(system);
            this.selectedSystemTab?.nativeElement.focus();
        }
    }

    private updatePreselectedSystemId(): void {
        let system: System | undefined = this.systemsDictionary[this.preselectedIndex];
        if (system === undefined) {
            this.preselectedSystemId = '';
        } else {
            this.preselectedSystemId = system.id;
        }
    }

    private displaySystem(value: boolean): void {
        this.is_systems_list_open = value;
        this.renderer.setStyle(this.listSystem.nativeElement, 'display', value ? 'block' : 'none');
        this.renderer.setStyle(this.closeSystem.nativeElement, 'display', value ? 'block' : 'none');
        if (window.screen.width < 600) {
            this.removeOrAddScrollToBody(value);
        }
    }

    private removeOrAddScrollToBody(value: boolean): void {
        if (!this.browserService.isBrowser) {
            return;
        }

        let body: any = document.querySelector('body');
        this.renderer.setStyle(body, 'height', value ? '100%' : 'auto');
        this.renderer.setStyle(body, 'overflow', value ? 'hidden' : 'auto');
    }

    @HostListener('window:resize', ['$event'])
    public onResize(event: any): void {
        if (!event.target.visualViewport || !event.target.visualViewport.width) {
            return;
        }
        if (!this.browserService.isBrowser || event.target.visualViewport.width >= 600) {
            this.removeOrAddScrollToBody(false);

            return;
        }
        this.removeOrAddScrollToBody(this.is_systems_list_open);
    }

    @tuiPure
    private removeAccents(str: string): string {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }

    private filterSystemsByName(systemsFiltered: Array<System>, text: string): Array<System> {
        if (text === '') {
            return systemsFiltered;
        }
        let expression: RegExp = new RegExp('(?:^|\\W)' + text + '.*', 'i');

        return systemsFiltered.filter((system): boolean => {
            return expression.test(
                [
                    system.attributes.name,
                    system.attributes.alternative_name,
                    this.removeAccents(system.attributes.name && system.attributes.alternative_name),
                    system.id,
                    system.relationships.currency.data?.id,
                    system.relationships.currency.data?.attributes.title
                ].join(' ')
            );
        });
    }
}
