import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class TransactionManagerService {
    public transactionKey: string = '';
    public transactionMid: string = '';

    public setTransactionKey(transactionKey: string): void {
        this.transactionKey = transactionKey;
    }

    public setTransactionMid(transactionMid: string): void {
        this.transactionMid = transactionMid;
    }
}
