import { Inject, Injectable, inject } from '@angular/core';
import { System } from '@app/core/resources/system';
import { LinkService } from '@app/core/services/link-service';
import { environment } from './../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { SeoService } from './seo.service';
import { OgService } from './og.service';

export interface IMicroData {
    priceAmount?: string;
    currency?: string;
    item_id?: string;
    item_group_id?: string;
}
@Injectable({
    providedIn: 'root'
})
export class HeaderService {
    private translateService: TranslateService;
    private seoService: SeoService;
    private ogService: OgService;

    public constructor(private link: LinkService, @Inject(DOCUMENT) private document: Document) {
        this.translateService = inject(TranslateService);
        this.seoService = inject(SeoService);
        this.ogService = inject(OgService);
    }

    public initializeSeoAndLanguageSettings(lang: string = ''): void {
        this.document.documentElement.lang = lang !== '' ? lang : 'es';
        combineLatest([
            this.translateService.get('meta_add_header_default_title' + lang),
            this.translateService.get('meta_keywords' + lang),
            this.translateService.get('meta_add_header_default_description' + lang)
        ]).subscribe((langs: Array<string>) => {
            this.seoService.addTitle(langs[0]);
            this.seoService.addMetaName('keywords', langs[1]);
            this.seoService.addMetaName('description', langs[2]);
        });
        this.seoService.addAlternateLink();
        this.ogService.addOpenGraphMetadata({ url: lang });
        this.seoService.addOrUpdateMetaProperty('fb:pages', '222481028339663');
        if (!['es-BO', 'es-CO', 'es-MX', 'en-US', 'es-VE'].includes(lang)) {
            this.link.addTag({ rel: 'canonical', href: environment.CANONICAL_ORIGIN_URL + (lang !== '' ? lang : '') });
        }
    }

    public updateaSeoAndLanguageSettings(
        section: 'a' | 'b' | 'cobrar',
        sendSystem: System,
        receiveSystem: System,
        lang: string = ''
    ): void {
        if (!sendSystem || !receiveSystem) {
            return;
        }
        combineLatest(
            this.translateService.get('meta_exchange_display', {
                element_one: sendSystem.attributes.alternative_name,
                element_two: receiveSystem.attributes.alternative_name
            }),
            this.translateService.get('meta_keywords_update', {
                element_one: sendSystem.attributes.name,
                element_two: receiveSystem.attributes.name
            }),
            this.translateService.get('meta_add_header_update_description', {
                element_one: sendSystem.attributes.name,
                element_two: receiveSystem.attributes.name
            }),
            this.translateService.get('meta_set_og_update_title', {
                element_one: sendSystem.attributes.name,
                element_two: receiveSystem.attributes.name
            })
        ).subscribe((langs: Array<string>) => {
            this.seoService.addTitle(langs[0]);
            this.seoService.addOrUpdateMetaProperty('twitter:title', langs[0]);
            this.seoService.addMetaName('keywords', langs[1]);
            this.seoService.addMetaName('description', langs[2]);
            this.seoService.addOrUpdateMetaProperty('og:title', langs[3]);
        });

        let separator: string = section === 'cobrar' ? '-a-' : '/';
        let langSection: string = lang ? lang + '/' : '';
        const canonicalUrl: string = langSection + section + '/' + sendSystem.id + separator + receiveSystem.id;

        this.seoService.addCanonical(canonicalUrl);
        this.seoService.addOrUpdateMetaProperty('og:url', canonicalUrl);

        this.seoService.addAlternateLink(section, separator, sendSystem.id, receiveSystem.id);

        const ogImageUrl: string = environment.LEGACYURL + 'og/' + sendSystem.id + '-' + receiveSystem.id + '.png';

        this.seoService.addOrUpdateMetaProperty('twitter:image', ogImageUrl);
        this.seoService.addOrUpdateMetaProperty('og:image', ogImageUrl);
    }

    public addOrUpdateProductMicrodata(priceAmount: string, currency: string, item_id: string, item_group_id: string): void {
        this.seoService.addOrUpdateMetaProperty('product:brand', 'Saldoar');
        this.seoService.addOrUpdateMetaProperty('product:availability', 'in stock');
        this.seoService.addOrUpdateMetaProperty('product:condition', 'new');
        this.seoService.addOrUpdateMetaProperty('product:price:amount', priceAmount ?? '');
        this.seoService.addOrUpdateMetaProperty('product:price:currency', currency ?? '');
        this.seoService.addOrUpdateMetaProperty('product:retailer_item_id', item_id ?? '');
        this.seoService.addOrUpdateMetaProperty('product:item_group_id', item_group_id ?? '');
    }
}
