import { DocumentResource, Resource } from 'ngx-jsonapi';
import { BestRate } from '@app/core/resources/best-rate';

export class Currency extends Resource {
    public type: string = 'currencies';

    public attributes: {
        default_minimum: number;
        title: string;
        rank: number;
        price: number;
    } = {
        default_minimum: 0,
        title: '',
        rank: 0,
        price: 0
    };

    public relationships: { best_rates: DocumentResource<BestRate> } = {
        best_rates: new DocumentResource<BestRate>()
    };
}
